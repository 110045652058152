import React from 'react';

import Title from '../Title';
import { BlogDetails } from './style';

const index = (props) => {
  return (
    <BlogDetails>
      <div className="masthead">
        <img src={props.img} alt={props.title} />
      </div>

      <div className="container">
        <Title title={props.title} highlightBorder={false} headingOf="page" />
        <div className="blog-info">
          <div>
            {props.tag} &bull; {props.postedOn}
          </div>
        </div>

        <article>
          <h2 className="sr-only">{props.title}</h2>
          {props.children}
        </article>
      </div>
    </BlogDetails>
  );
};

export default index;
