import styled from 'styled-components';
import colors from '../../layout/globalColors';
import { device } from '../../layout/device';
import { Wrapper } from '../../layout/global-style';

export const BlogDetails = styled(Wrapper)`
  padding: 0 0 18rem;

  h1 {
    max-width: 80% !important;
    margin-bottom: 2rem !important;
  }

  h1::before {
    display: none;
  }

  .container {
    @media ${device.laptop} {
      max-width: 80%;
    }
  }

  .masthead {
    display: flex;
    justify-content: center;
    margin-bottom: 2.5rem;

    & img {
      width: 100vw;
      max-width: 1920px;
      max-height: 480px;
      object-fit: cover;
    }
  }

  .blog-info {
    display: flex;
    font-size: 1rem;
    font-weight: 500;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    opacity: 0.7;

    @media ${device.mobile} {
      margin-top: -1rem;
    }
  }

  article {
    color: ${colors.grey};
    font-size: 1.1rem;
    line-height: 1.5;

    @media ${device.laptop} {
      font-size: 1.2rem;
      line-height: 1.8;
      max-width: 80%;
    }

    h2 {
      margin-top: 3rem;
      margin-bottom: 1rem;
      font-size: 1.4rem;
      line-height: 1.4;
      color: white;

      @media ${device.laptop} {
        font-size: 1.8rem;
      }
    }

    h3 {
      font-size: 1.3rem;
      margin: 2.5rem 0 0.5rem;
    }

    ol,
    ul {
      margin-bottom: 2rem;
    }

    ol {
      list-style-type: decimal;
    }

    ul {
      list-style-type: disc;
    }

    ol,
    ul {
      padding-left: 2rem;

      > li {
        margin-bottom: 0.5rem;
      }
    }

    > p:first-of-type::first-letter {
      font-size: 2rem;
    }

    a {
      color: ${colors.secondary};

      &:hover,
      &:focus {
        color: ${colors.secondaryDark};
      }
    }

    blockquote {
      padding: 3rem 3rem 2.4rem;
      color: #f1f1f1;
      font-family: 'Libre Baskerville';
      font-size: 1.2rem;
      letter-spacing: 1px;
      border-radius: 1rem;
      max-width: 540px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 2rem;
      margin-bottom: 2rem;
      background: linear-gradient(
        180deg,
        ${colors.spaceCadet},
        ${colors.spaceCadet2}
      );
      box-shadow: 0 14px 50px rgba(0, 0, 0, 0.15);
      position: relative;

      &::before {
        content: '“';
        font-family: 'Inter';
        font-size: 5rem;
        position: absolute;
        top: 0rem;
        left: 1rem;
        opacity: 0.2;
      }

      cite {
        text-align: right;
        display: block;
      }
    }

    img {
      border-radius: 1rem;
      max-width: 90%;
      margin: 1rem 0;

      @media ${device.laptop} {
        max-width: 40rem;
      }

      &.size-mini {
        max-width: 50%;
        float: right;
        margin-left: 1rem;

        @media ${device.laptop} {
          margin-left: 2rem;
          max-width: 20rem;
        }
      }
    }
  }
`;
